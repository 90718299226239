import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initHero = () => {
    Alpine.data('initHero', () => ({
        init() {
            new Splide(this.$el, {
                autoplay: true,
                arrows: false,
                pagination: true,
                perPage: 1,
                perMove: 1,
                focus: 0,
                type: 'fade',
                rewind: true,
                pauseOnHover: false,
                pauseOnFocus: false,
                ResetProgress: false,
                speed: 2000,
                interval: 8000,
                lazyLoad: 'nearby',
            }).mount()
        },
    }))
}
