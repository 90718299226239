import Alpine from 'alpinejs'

export const initHeader = () => {
    Alpine.data('header', () => ({
        hidden: true,
        colors: ['fuchsia', 'sea', 'grass', 'rose'],
        lastScroll: 0,
        headerClass: '',
        chosenColor: '',
        openSearch: false,
        init() {
            this.setupScrollListener()
        },
        handleClick() {
            this.hidden = !this.hidden
            if (!this.hidden) this.chosenColor = this.colors[Math.floor(Math.random() * this.colors.length)]
            if (this.headerClass.includes('menu-opened')) {
                this.headerClass = this.headerClass.replace('menu-opened', '')
            } else {
                this.headerClass = this.headerClass + ' menu-opened'
            }
        },
        setupScrollListener() {
            window.addEventListener('scroll', this.handleScroll.bind(this))
        },
        handleScroll() {
            const currentScroll = window.scrollY

            if (currentScroll <= 0) {
                this.headerClass = this.headerClass.replace('scroll-up', '')
                this.headerClass = this.headerClass.replace('scroll-down', '')
                return
            }

            if (currentScroll > this.lastScroll && !this.headerClass.includes('scroll-down')) {
                this.headerClass = this.headerClass + ' scroll-down'
                if (this.headerClass.includes('scroll-up')) {
                    this.headerClass = this.headerClass.replace('scroll-up', '')
                }
            } else if (currentScroll < this.lastScroll && this.headerClass.includes('scroll-down')) {
                this.headerClass = this.headerClass + ' scroll-up'
                if (this.headerClass.includes('scroll-down')) {
                    this.headerClass = this.headerClass.replace('scroll-down', '')
                }
            }

            this.lastScroll = currentScroll
        },
        collapseMenuItem(el: HTMLElement | null) {
            if (el) {
                const nextElement = el.nextElementSibling as HTMLElement | null
                const parentElement = el.parentElement as HTMLElement | null

                el.classList.toggle('active')

                if (nextElement) {
                    nextElement.classList.toggle('active')
                }

                if (parentElement) {
                    parentElement.classList.toggle('active')
                }
            }
        },
        toggleSearch() {
            this.openSearch = !this.openSearch
            if (this.openSearch === true) this.$refs.searchInput.focus()
        },
    }))
}
