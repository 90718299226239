import GLightbox from 'glightbox'

export const initLightbox = () => {
    var g = GLightbox({
        touchNavigation: true,
        autoplayVideos: true,
        selector: '.glightbox2',
    })

    const urlParams = new URLSearchParams(window.location.search)
    const isFullscreen = urlParams.has('fs')

    if (isFullscreen) {
        g.open()
    }
}
