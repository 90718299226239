import Alpine from 'alpinejs'

export const initSubmenu = () => {
    Alpine.data('submenu', () => ({
        open: false,
        toggleSubMenu() {
            this.open = !this.open
        },
    }))
}
