import Alpine from 'alpinejs'

export const initMenuToggle = () => {
    Alpine.data('menuToggle', () => ({
        hidden: true,
        colors: ['fuchsia', 'sea', 'grass', 'rose'],
        chosenColor: '',
        init() {},
        handleClick() {
            this.hidden = !this.hidden

            if (!this.hidden) this.chosenColor = this.colors[Math.floor(Math.random() * this.colors.length)]
        },
    }))
}
