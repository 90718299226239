import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const initSlider = () => {
    Alpine.data('initImageSlider', () => ({
        init() {
            new Splide(this.$el, {
                gap: 30,
                isNavigation: false,
                arrows: true,
                pagination: false,
            }).mount()
        },
    }))

    Alpine.data('initImagesSlider', () => ({
        init() {
            new Splide(this.$el, {
                isNavigation: false,
                arrows: true,
                pagination: false,
                gap: 30,
                perMove: 1,
                perPage: 4,
                updateOnMove: true,
                breakpoints: {
                    475: {
                        perPage: 1,
                    },
                    640: {
                        perPage: 2,
                    },
                    1024: {
                        perPage: 3,
                    },
                },
            }).mount()
        },
    }))

    Alpine.data('initQuoteSlider', () => ({
        init() {
            new Splide(this.$el, {
                arrows: true,
                pagination: false,
                perMove: 1,
                perPage: 1,
            }).mount()
        },
    }))
}
